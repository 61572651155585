// Main imports
import { Routes, Route, Navigate } from "react-router-dom";

// Views and components
// import Brands from "./views/Brands/Brands";
// import Catering from "./views/Catering/Catering";
import Footer from "./components/ui/Footer/Footer";
import Home from "./views/Home/Home";
// import Locations from "./views/Locations/Locations";

// Data and context
// import restaurantData from "./data/restaurantData.json";
import WebplaceProvider from "./store/webplace-provider";

// Utilities
import "./App.css";
// import EmailSignup from "./views/EmailSignup/EmailSignup";

// GET INDEX OF NAVBAR OBJECT
// const findObject = (arr, objName) => {
//   const object = arr.findIndex((item) => item.title === objName);
//   return object;
// };

// const navbarData = restaurantData.navbar_items.options;

const App = () => {
  return (
    <WebplaceProvider>
      <Routes>
        <Route path="/" element={<Home />} />


        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </WebplaceProvider>
  );
};

export default App;
